import React from "react";

import "./style.css";

import { connect } from "react-redux";
import { IMedicamentSelection } from "../../interfaces/IMedicamentSelection";
import { IIntoleranceSelection } from "../../interfaces/IIntoleranceSelection";
import { IViaAdministration } from "../../interfaces/IViaAdministration";
import { setMedicamentAction, setViaAction, setIntoleranceAction, setIntoleranceTwoAction } from "../../redux/actions";
import { IIntoleranceTwoSelection } from "../../interfaces/IIntoleranceTwoSelection";

import faces from "../../logos/caras.png";
import DataApi from "../../API/DataApi";
import PrescriptionBox from "./PrescriptionBox/PrescriptionBox";
import Prescription from "../../API/Entities/Prescription";
import AdministrationWeyInput from "../AdministrationWeyInput/AdministrationWeyInput";
import Loading from "../Loading/Loading";
import AdvanceSearch from "../AdvancedSearch/AdvancedSearch";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import faceBlue from "../../logos/faceBlue.png";
import faceGreen from "../../logos/faceGreen.png";
import faceRed from "../../logos/faceRed.png";
import faceYellow from "../../logos/faceYellow.png";
import none from "../../logos/none.png";

interface IProps {
  medicamentoSelecionadas: IMedicamentSelection;
  intoleranciasSelecionadas: IIntoleranceSelection;
  intoleranciasSelecionadasTwo: IIntoleranceTwoSelection;
  viaAdministration: IViaAdministration;
  setMedicament(medicament: IMedicamentSelection): void;
  history: any[];
  location: any;
}

interface IState {
  via: string;
  intolerancia: string;
  ready: boolean;
  titleMadicamento: string;
  results: any;
  viaAdministration: string;
  intoleranciasSelecionadas: string;
  medicamentoSelecionadas: string;
  load: boolean;
  medicament: string | null;
  loadAdministration: boolean;
}

interface IGlobalActionPreops {
  setIntolerance(intoleranciasSelecionadas: IIntoleranceSelection): void;
  setIntoleranceTwo(intoleranciasTwoSelecionadas: IIntoleranceTwoSelection): void;
  setViaAdministration(viaAdministration: IViaAdministration): void;
}

type TProps = IProps & IGlobalActionPreops;

class SearchIntolerance extends React.PureComponent<TProps, IState> {
  constructor(props: TProps) {
    super(props);

    this.state = {
      via: "",
      intolerancia: "",
      titleMadicamento: "",
      ready: false,
      results: [],
      viaAdministration: "",
      intoleranciasSelecionadas: "",
      medicamentoSelecionadas: "",
      load: true,
      medicament: null,
      loadAdministration: false,
    };
    this.callsApi = this.callsApi.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.searchAdvanced = this.searchAdvanced.bind(this);
    this.onSubmitHome = this.onSubmitHome.bind(this);
    this.onSubmitButton = this.onSubmitButton.bind(this);
  }

  async onSubmit(event: any) {
    sessionStorage.clear();
    const { viaAdministration, intoleranciasSelecionadas } = this.state;
    const medicamento = event.target.value;

    this.setState({ load: true, medicament: medicamento });

    if (event.charCode === 13 && medicamento) {
      this.setState({ ready: false });
      const results = await this.callsApi(medicamento, viaAdministration, intoleranciasSelecionadas);
      const myArray = {
        prescription: medicamento,
        intoleranciasSelecionadas: intoleranciasSelecionadas,
        viaAdministration: viaAdministration,
      };

      sessionStorage.setItem("prescription", JSON.stringify(myArray));
      this.setState({ results: results, titleMadicamento: medicamento, ready: true });
    }
  }

  async onSubmitButton() {
    this.setState({ ready: false, loadAdministration: false });
    sessionStorage.clear();

    const { viaAdministration, intoleranciasSelecionadas, medicament, titleMadicamento } = this.state;

    if (medicament === null) {
      const results = await this.callsApi(titleMadicamento, viaAdministration, intoleranciasSelecionadas);
      const myArray = {
        prescription: medicament,
        intoleranciasSelecionadas: intoleranciasSelecionadas,
        viaAdministration: viaAdministration,
      };

      sessionStorage.setItem("prescription", JSON.stringify(myArray));

      this.setState({ results: results, titleMadicamento: titleMadicamento, ready: true });
    } else {
      const results = await this.callsApi(medicament, viaAdministration, intoleranciasSelecionadas);
      const myArray = {
        prescription: medicament,
        intoleranciasSelecionadas: intoleranciasSelecionadas,
        viaAdministration: viaAdministration,
      };

      sessionStorage.setItem("prescription", JSON.stringify(myArray));

      this.setState({
        results: results,
        titleMadicamento: medicament,
        viaAdministration: viaAdministration,
        intoleranciasSelecionadas: intoleranciasSelecionadas,
        ready: true,
      });
    }
  }

  onSubmitHome() {
    this.setState({ loadAdministration: false }, () => {
      this.props.history.push("/");
    });
  }

  searchAdvanced(event: any) {
    event.preventDefault();
    event.stopPropagation();

    const avanceSearch = document.getElementById("loadAdministration");
    const buttonSearch = document.getElementById("div-avance-search");
    const dropSoen = document.getElementById("container-style-drop-soen");
    const popupIntolerances = document.getElementById("popup-intolerances");
    const postTitle = document.getElementById("post_title");
    const listIntolerance = document.getElementById("list-intolerance-drop-down");
    const label = document.getElementById("title-checkbox");

    if (avanceSearch?.style.display === "none") {
      avanceSearch.style.display = "block";
    } else if (avanceSearch?.style.display === "block") {
      avanceSearch.style.display = "none";
    }

    buttonSearch?.addEventListener("click", this.searchAdvanced, false);

    document.addEventListener(
      "click",
      (event: any) => {
        const click = event.target;

        if (
          avanceSearch?.style.display === "block" &&
          click != dropSoen &&
          click != popupIntolerances &&
          click.id != postTitle?.id &&
          click != listIntolerance &&
          click.id != label?.id
        ) {
          avanceSearch.style.display = "none";
        }
      },
      false
    );
  }

  async callsApi(medicamento: string, via: string, intolerancia: string) {
    if (via === "Vía administracion") {
      via = "";
    }
    const results: any = await DataApi.Search(medicamento, via, intolerancia);

    this.props.setMedicament({ prescription: medicamento });

    return results;
  }

  componentDidUpdate(prevProps: any) {
    if (
      this.props.intoleranciasSelecionadas !== prevProps.intoleranciasSelecionadas ||
      this.props.viaAdministration !== prevProps.viaAdministration
    ) {
      const {
        medicamentoSelecionadas: { prescription },
        intoleranciasSelecionadas: { intoleranciasSelecionadas },
        viaAdministration: { viaAdministration },
      } = this.props;

      this.setState({
        medicamentoSelecionadas: prescription,
        intoleranciasSelecionadas: intoleranciasSelecionadas,
        viaAdministration: viaAdministration,
      });

      const myArray = {
        prescription: prescription,
        intoleranciasSelecionadas: intoleranciasSelecionadas,
        viaAdministration: viaAdministration,
      };

      sessionStorage.setItem("prescription", JSON.stringify(myArray));
    }
  }

  /**
   * Llamada a API
   * @param item
   */
  async componentDidMount() {
    const sesion = sessionStorage.getItem("prescription");

    if (sesion === null || sesion === undefined) {
      const {
        medicamentoSelecionadas: { prescription },
        intoleranciasSelecionadas: { intoleranciasSelecionadas },
        intoleranciasSelecionadasTwo: { intoleranciasSelecionadasTwo },
        viaAdministration: { viaAdministration },
      } = this.props;

      this.setState({
        medicamentoSelecionadas: prescription,
        intoleranciasSelecionadas: intoleranciasSelecionadas,
        viaAdministration: viaAdministration,
      });

      const results: any = await this.callsApi(prescription, viaAdministration, intoleranciasSelecionadas);

      if (results) {
        this.setState({
          ready: true,
          results: results,
          titleMadicamento: prescription,
        });
      }

      const myArray = {
        prescription: prescription,
        intoleranciasSelecionadas: intoleranciasSelecionadas,
        viaAdministration: viaAdministration,
      };

      sessionStorage.setItem("prescription", JSON.stringify(myArray));
    } else if (sesion != null) {
      const myArray = JSON.parse(sesion);

      this.setState({
        medicamentoSelecionadas: myArray.prescription,
        intoleranciasSelecionadas: myArray.intoleranciasSelecionadas,
        viaAdministration: myArray.viaAdministration,
      });
      this.props.setIntolerance({ intoleranciasSelecionadas: myArray.intoleranciasSelecionadas });
      this.props.setIntoleranceTwo({ intoleranciasSelecionadasTwo: myArray.intoleranciasSelecionadasTwo });
      this.props.setViaAdministration({ viaAdministration: myArray.viaAdministration });
      this.props.setMedicament({ prescription: myArray.prescription });
      const results: any = await this.callsApi(
        myArray.prescription,
        myArray.viaAdministration,
        myArray.intoleranciasSelecionadas
      );

      if (results) {
        this.setState({
          ready: true,
          results: results,
          titleMadicamento: myArray.prescription,
        });
      }
    }
  }

  render() {
    const { ready, results, titleMadicamento, loadAdministration } = this.state;

    if (!ready) {
      return <Loading />;
    }

    return (
      <div className="root results-view">
        <Header />
        <div className="results-view-container">
          <div className="results-title">
            <span>Resultados para:</span>
            <h1 className="textTittle">{titleMadicamento}</h1>
          </div>

          <div className="results-container">
            <div className="search-form">
              <div className="selectors">
                <input
                  id="input-search-medicamet"
                  type="text"
                  className="form-control mr-sm-2 input-search-medicamet"
                  placeholder={titleMadicamento}
                  onChange={this.onSubmit}
                />
                <AdministrationWeyInput />
                <div id="div-avance-search" className="form-control intolerances-button">
                  <AdvanceSearch />
                </div>
              </div>
              <button className="button action-button" onClick={this.onSubmitButton}>
                Buscar
              </button>
            </div>
            <ul className="container-legend">
              <li>
                <img className="img-recomendado" src={faceGreen} />
                <p className="text-recomendado">Apto</p>
              </li>
              <li>
                <img className="img-permitido" src={faceBlue} />
                <p className="text-permitido">Apto con consideraciones</p>
              </li>
              <li>
                <img className="img-clasificar" src={none} />
                <p className="text-clasificar">Sin clasificar</p>
              </li>
              <li>
                <img className="img-no-recomendado" src={faceYellow} />
                <p className="text-no-recomendado">No recomendado</p>
              </li>
              <li>
                <img className="img-no-permitido" src={faceRed} />
                <p className="text-no-permitido">Contraindicado</p>
              </li>
            </ul>
            <div className="result-prescriptions">
              <div className="result-prescriptions-row" style={{ justifyContent: "center" }}>
                {results.prescriptions.length === 0 ? (
                  <div className="container-hidde no-results">
                    <h4 className="title-hidde">Este tipo de conbinación no existe para este medicamento</h4>
                  </div>
                ) : (
                  <div className="container-results">
                    {results.prescriptions.map((prescription: Prescription, index: any) => {
                      return (
                        <div key={index} className="container-results-prescriptions">
                          <PrescriptionBox prescription={prescription} {...this.props} />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            {/*
            <div className="faces">
              <img src={faces} />
            </div>
            */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  medicamentoSelecionadas,
  intoleranciasSelecionadas,
  intoleranciasSelecionadasTwo,
  viaAdministration,
}: IProps) => ({
  medicamentoSelecionadas,
  intoleranciasSelecionadas,
  viaAdministration,
  intoleranciasSelecionadasTwo,
});

const mapDispatchToProps = {
  setMedicament: setMedicamentAction,
  setIntolerance: setIntoleranceAction,
  setIntoleranceTwo: setIntoleranceTwoAction,
  setViaAdministration: setViaAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchIntolerance);
