import React from "react";
import Prescription from "../../../API/Entities/Prescription";
import ColorBox from "./ColorBox";
import flecha from "../../../logos/flecha.png";

import Good1 from "../../../logos/1.png";
import Standard2 from "../../../logos/2.png";
import Warning3 from "../../../logos/3.png";
import Danger4 from "../../../logos/4.png";
import Loading from "../../Loading/Loading";
import WarningLogo from "../../../logos/warning-yellow.png";
import Tooltip from "@material-ui/core/Tooltip";

import "../style.css";

import { IPrescriptions } from "../../../interfaces/IPrescriptions";
import { setPrescriptionAction } from "../../../redux/actions";
import { connect } from "react-redux";

interface IProps {
  prescription: Prescription;
  history: any[];
}

interface IGlobalActionProps {
  setPrescription(prescription: IPrescriptions): void;
}

interface IState {
  ready: boolean;
  readyResponsi: boolean;
  activeItem: number | boolean;
  returnBox: string | null;
  face: string;
  result: any;
}

type TProps = IProps & IGlobalActionProps;

class PrescriptionBox extends React.PureComponent<TProps, IState> {
  constructor(props: TProps) {
    super(props);

    this.state = {
      ready: false,
      readyResponsi: true,
      activeItem: false,
      returnBox: "",
      face: "",
      result: [],
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.selectionColor = this.selectionColor.bind(this);
    this.onChangeSateBox = this.onChangeSateBox.bind(this);
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.prescription !== prevProps.prescription) {
      const {
        prescription: { LeafletDocument, TechnicalDocument, Description },
      } = this.props;
      this.selectionColor();
      console.log(this.props.prescription);
      this.props.setPrescription({
        resultsAll: this.props.prescription,
        LeafletDocument,
        TechnicalDocument,
        Description,
      });
    }
  }

  componentDidMount() {
    this.selectionColor();
    const { prescription } = this.props;
    this.props.setPrescription({
      resultsAll: prescription,
      LeafletDocument: prescription.LeafletDocument,
      TechnicalDocument: prescription.TechnicalDocument,
      Description: prescription.Description,
    });
    this.setState({ ready: true });

    if (window.outerWidth < 420) {
      this.setState({ readyResponsi: false });
    } else {
      this.setState({ readyResponsi: true });
    }
  }

  selectionColor() {
    const { prescription } = this.props;

    if (prescription.Valoration.toString() === "1") {
      const result = ColorBox.Good();
      this.setState({ result: result[0], face: Good1 });
    } else if (prescription.Valoration.toString() === "0.25") {
      const result = ColorBox.Warning();
      this.setState({ result: result[0], face: Warning3 });
    } else if (prescription.Valoration.toString() === "0.75") {
      const result = ColorBox.Standard();
      this.setState({ result: result[0], face: Standard2 });
    } else if (prescription.Valoration.toString() === "0.5") {
      const result = ColorBox.Unclassified();
      this.setState({ result: result[0], face: "" });
    } else {
      const result = ColorBox.Danger();
      this.setState({ result: result[0], face: Danger4 });
    }
  }

  onSelectItem(index: number | boolean, properties: string | null) {
    this.setState({ activeItem: index, returnBox: properties });
  }

  getItemDescription() {
    const { activeItem } = this.state;

    switch (activeItem) {
      case 1:
        return this.composeExcipientDescription();
      case 2:
        return this.composeExcipienteActivePrincipales();
      case 3:
        return this.composeExcipienteAdministration();
    }
  }

  composeExcipientDescription() {
    const { prescription } = this.props;

    return prescription.Excipients.map((excipient) => {
      return (
        <div className="font-textBox" style={{ marginLeft: "16px" }}>
          {excipient.Edo.toLowerCase()}
        </div>
      );
    });
  }

  composeExcipienteActivePrincipales() {
    const { prescription } = this.props;

    return prescription.ActivePrinciples.map((activePrinciples) => {
      return (
        <div className="font-textBox" style={{ marginLeft: "16px" }}>
          {activePrinciples.Name.toLowerCase()}
        </div>
      );
    });
  }

  composeExcipienteAdministration() {
    const { prescription } = this.props;

    return prescription.AdministationWays.map((administationWays) => {
      return (
        <div className="font-textBox" style={{ marginLeft: "16px" }}>
          {administationWays.Name.toLowerCase()}
        </div>
      );
    });
  }

  onChangeSateBox(index: boolean) {
    index === true ? this.setState({ readyResponsi: false }) : this.setState({ readyResponsi: true });
  }

  onSubmit() {
    const { prescription } = this.props;

    this.props.setPrescription({
      resultsAll: prescription,
      LeafletDocument: prescription.LeafletDocument,
      TechnicalDocument: prescription.TechnicalDocument,
      Description: prescription.Description,
    });
    this.props.history.push("/searches/dataSheet");
  }

  render() {
    const { activeItem, result, returnBox, face, readyResponsi } = this.state;
    const { prescription } = this.props;

    if (result === undefined) {
      return <Loading />;
    }

    return (
      <div className="cntainer-box-principle-prescriptions">
        <div className="box-principle">
          <div className="box d-none d-md-block d-xl-block" style={{ backgroundColor: result.color1 }}>
            <div className="box">
              <div className="box-title">
                <h5 className="style-text-title">{prescription.Description.toLowerCase()}</h5>
              </div>
              <img className="face-image-box" src={face} />
            </div>
          </div>
          <div
            className="box d-block d-sm-block d-md-none"
            style={{ backgroundColor: result.color1 }}
            onClick={() => this.onChangeSateBox(readyResponsi)}
          >
            <div className="box">
              <h5 className="style-text-title">{prescription.Description.toLowerCase()}</h5>
              <img className="face-image-box" src={face} />
            </div>
          </div>
          {activeItem ? (
            <div
              className="box-active"
              style={{ backgroundColor: result.color3 }}
              onClick={() => this.onSelectItem(false, null)}
            >
              <div className="box-text-principal">
                <strong className="text-strong">
                  <div className="separation-flecha">
                    <div className="image-flecha-container" style={{ backgroundColor: result.color1 }}>
                      <img className="image-flecha" src={flecha} />
                    </div>
                    <p style={{ marginLeft: "7px", color: result.color1 }}>{returnBox}</p>
                  </div>
                  <div className="scrowll-box">{this.getItemDescription()}</div>
                </strong>
              </div>
            </div>
          ) : (
            <div>
              <button
                className="boxSecondary"
                style={{ backgroundColor: result.color2 }}
                onClick={() => this.onSelectItem(1, "Excipientes")}
              >
                <p className="font-text">Excipientes</p>
                <div className="container-warning-logo">
                  {prescription.Comment.length === 0 ? null : (
                    <Tooltip title={prescription.Comment} placement="right">
                      <img data-tip data-for="registerTip" src={WarningLogo} width="20px" />
                    </Tooltip>
                  )}
                </div>
              </button>
              <button
                className="boxSecondary"
                style={{ backgroundColor: result.color3 }}
                onClick={() => this.onSelectItem(2, "Principio activo")}
              >
                <p className="font-text">Principio activo</p>
              </button>
              <button
                className="boxSecondary"
                style={{ backgroundColor: result.color4 }}
                onClick={() => this.onSelectItem(3, "Vías de administración")}
              >
                <p className="font-text">Vías de administración</p>
              </button>
            </div>
          )}
          <button className="box-finaly" onClick={this.onSubmit} style={{ backgroundColor: result.color1 }}>
            <p className="font-text">
              <strong>+info</strong>
            </p>
          </button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setPrescription: setPrescriptionAction,
};

export default connect(null, mapDispatchToProps)(PrescriptionBox);
