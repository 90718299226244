import React from "react";

import { setIntoleranceAction } from "../../redux/actions";
import { connect } from "react-redux";
import { IIntoleranceSelection } from "../../interfaces/IIntoleranceSelection";

import { Picky } from 'react-picky';
import 'react-picky/dist/picky.css'; 

import DataApiIntolerance from "../../APIIntolance/DataApiIntolerance";
import Checkbox from "./Checkbox/Checkbox";

interface IProps {
  intoleranciasSelecionadas: IIntoleranceSelection;
}

interface IState {
  itemsIntolerances: any[] | null;
  itemIntolerance: any;
  checkcout: boolean;
}

interface IGlobalActionPreops {
  setIntolerance(intoleranciasSelecionadas: IIntoleranceSelection): void;
}

type TProps = IProps & IGlobalActionPreops;

class AdvanceSearch extends React.PureComponent<TProps, IState> {
  constructor(props: TProps) {
    super(props);

    this.state = {
      itemsIntolerances: null,
      itemIntolerance: [],
      checkcout: false,
    };
    this.callsApi = this.callsApi.bind(this);
    this.handleChangeChk = this.handleChangeChk.bind(this);
    this.saveArray = this.saveArray.bind(this);
    this.weCheck = this.weCheck.bind(this);
  }

  componentDidMount() {
    //Si existe me lo pinta de redux o sesion Si no de la Api directamente
    this.callsApi().then((data: any) => {
      this.setState({ itemIntolerance: data.intolerances });
    });
    const {
      intoleranciasSelecionadas: { intoleranciasSelecionadas },
    } = this.props;

    if (intoleranciasSelecionadas != undefined) {
      console.log(intoleranciasSelecionadas);

      this.setState({ itemsIntolerances: intoleranciasSelecionadas });
    }
  }

  async callsApi() {
    const results: any[] = await DataApiIntolerance.Search();

    return results;
  }

  weCheck(intoleranciasSelecionadas: any, event: any) {
    return (
      intoleranciasSelecionadas.findIndex((p: any) => {
        return p.id == event;
      }) >= 0
    );
  }

  saveArray(itemsIntolerances: any, event: any) {
    const {
      intoleranciasSelecionadas: { intoleranciasSelecionadas },
    } = this.props;
    const arrayIntolerances: any = [];

    const weCheck = this.weCheck(intoleranciasSelecionadas, event);
    const index = intoleranciasSelecionadas.findIndex((index: any) => {
      return index.id == event;
    });

    if (weCheck === false) {
      itemsIntolerances.map((items: any) => {
        arrayIntolerances.push({ id: items.id, checkbox: true });
      });
      arrayIntolerances.push({ id: event, checkbox: true });

      return arrayIntolerances;
    } else {
      intoleranciasSelecionadas.splice(index, 1);

      return intoleranciasSelecionadas;
    }
  }

  /**
   *
   * @param e
   * trae el value lo guarda en redux y sesion
   */
  handleChangeChk(e: any) {
    const event = e.target.value;
    const { itemsIntolerances } = this.state;

    if (itemsIntolerances != null) {
      const arrayIntolerances = this.saveArray(itemsIntolerances, event);
      const sesion = sessionStorage.getItem("prescription");

      if (sesion != null) {
        const sesionParse = JSON.parse(sesion);
        const myArray = {
          prescription: sesionParse.prescription,
          intoleranciasSelecionadas: arrayIntolerances,
          viaAdministration: sesionParse.viaAdministration,
        };

        sessionStorage.setItem("prescription", JSON.stringify(myArray));
      }

      this.setState({ itemsIntolerances: arrayIntolerances });
      this.props.setIntolerance({ intoleranciasSelecionadas: arrayIntolerances });
    } else {
      this.setState({ itemsIntolerances: [{ id: event }] });
      this.props.setIntolerance({ intoleranciasSelecionadas: [{ id: event }] });
    }
  }

  render() {
    const { itemIntolerance } = this.state;
      const { intoleranciasSelecionadas: { intoleranciasSelecionadas } } = this.props;

      return (
          <>
              <Picky
                  options={itemIntolerance}
                  open={false}
                  valueKey="alpha3Code"
                  labelKey="name"
                  multiple={true}
                  includeSelectAll={false}
                  className="menu-outer-top"
                  includeFilter={false}
                  placeholder={"Intolerancias"}
                  allSelectedPlaceholder={"intolerancias"}
                  dropdownHeight={300}
                  render={({
                      item,
                      isSelected,
                      selectValue
                  }) => {
                      return (
                          <li
                              className={isSelected ? "selected" : ""} 
                              key={item.id}
                              onClick={() => selectValue(item)}
                          >
                              <Checkbox data={item} itemsIntolerances={intoleranciasSelecionadas} onPress={this.handleChangeChk} />
                          </li>
                      );
                  }}
              />
          </>
       )
    }
}

const mapDispatchToProps = {
  setIntolerance: setIntoleranceAction,
};

const mapStateToProps = ({ intoleranciasSelecionadas }: IProps) => ({
  intoleranciasSelecionadas,
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvanceSearch);
