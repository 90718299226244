import ActivePrinciples from "../Entities/ActivePrinciples";

export default class ActivePrinciplesMapper {
    /**
     * Genera un arrays de objetos AdministrationWay partido de un json
     * @param item
     */
    static map(item: any): ActivePrinciples {
        const activePrinciples = new ActivePrinciples();
        activePrinciples.NRO = item.NRO;
        activePrinciples.Code = item.Code;
        activePrinciples.Name = item.Name;

        return activePrinciples;
    }
    /**
     * Genera un arrays de objetos AdministrationWay partido de un json
     * @param item
     */
    static mapArray(items: any[]): ActivePrinciples[] {
        return items.map(item => {
            return ActivePrinciplesMapper.map(item);
        });
    }
}
