import Intolerance from "../Entities/Intolerance";

export default class IntolerancesMapper {
    /**
     * Genera un objeto PrescriptionMapper partido de un json
     * @param item
     */
    static map(item: any): Intolerance {
        const intolerance = new Intolerance();
        intolerance.id = item.ID;
        intolerance.post_title = item.post_title;

        return intolerance;
    }

    /**
     * Genera un arrays de objetos PrescriptionMapper partido de un json
     * @param items
     */
    static mapArray(items: any[]): Intolerance[] {
        return items.map(item => {
            return IntolerancesMapper.map(item._source);
        });
    }
}