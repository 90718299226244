import { combineReducers } from "redux";

import medicamentoSelecionadas from "./medicamentReducers";
import intoleranciasSelecionadas from "./intoleranceReducers";
import intoleranciasSelecionadasTwo from "./intoleranceTwoReducers";
import prescription from "./prescriptionsReducers";
import viaAdministration from "./viaAdministrationReducers";

// Lo que guardo en el Store
export default combineReducers({
  medicamentoSelecionadas,
  intoleranciasSelecionadas,
  intoleranciasSelecionadasTwo,
  prescription,
  viaAdministration
});
