import React from "react";
import fundacion from "../../logos/logo_fundación.png";

export default class Header extends React.PureComponent {
  render() {
    return (
      <div id="header">
        <a href="/">
          <img src={fundacion} className="logo-header"></img>
        </a>
      </div>
    );
  }
}
