import React from "react";

import "../../styles/styles.css";

import { IIntoleranceSelection } from "../../interfaces/IIntoleranceSelection";

import AdministrationWeyInput from "../AdministrationWeyInput/AdministrationWeyInput";
import AdvanceSearch from "../AdvancedSearch/AdvancedSearch";
import medicame from "../../images/medicamen.jpg";

import Header from "../Common/Header";
import Footer from "../Common/Footer";

interface IProps {
  intolerancias: IIntoleranceSelection;
  history: any;
}

interface IState {
  load: boolean;
}

class SearchIntolerance extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      load: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.searchAdvanced = this.searchAdvanced.bind(this);
  }

  onSubmit() {
    this.setState({ load: false }, () => {
        this.props.history.push("/search/intolerance", { load: false });
    });
  }

  searchAdvanced(event: any) {
      event.preventDefault();
      event.stopPropagation();

      const avanceSearch = document.getElementById('loadAdministration');
      const buttonSearch = document.getElementById('intolerances-button');
      const dropSoen = document.getElementById('container-style-drop-soen');
      const popupIntolerances = document.getElementById('popup-intolerances');
      const postTitle = document.getElementById('post_title');
      const listIntolerance = document.getElementById('list-intolerance-drop-down');
      const label = document.getElementById('title-checkbox');

      if (avanceSearch?.style.display === "none") {
          avanceSearch.style.display = "block";
      } else if (avanceSearch?.style.display === "block") {
          avanceSearch.style.display = "none";
      }

      buttonSearch?.addEventListener("click", this.searchAdvanced, false);

      document.addEventListener("click", (event: any) => {
          const click = event.target;
          console.log(click.id);
          console.log(label);
          
          if (avanceSearch?.style.display === "block" && click != dropSoen &&
              click != popupIntolerances && click.id != postTitle?.id && click != listIntolerance && click.id != label?.id) {
            
              avanceSearch.style.display = "none";
          }
      }, false);
  }

  render() {

    return (
      <div className="home">
        <Header />
        <div className="cols">
          <div className="left-col" style={{ backgroundImage: `url(${medicame})` }}></div>
          <div className="right-col">
            <div className="search-form">
              <div className="box-textSearch">
                <div className="text">Y ahora escoge tu intolerancia y la vía de administración</div>
                <div className="selectors">
                <AdministrationWeyInput />
                <div
                   id="div-avance-search"
                   className="form-control intolerances-button"
                >
                   <AdvanceSearch />
                 </div>
                </div>
                <button className="button action-button" onClick={this.onSubmit}>
                  VER RESULTADOS
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default SearchIntolerance;
