import React from "react";

import "./style.css";
import "../../styles/styles.css";
import flecha from "../../logos/flecha.svg";
import Good1 from "../../logos/1.png";
import ok from "../../logos/ok.png";
import danger from "../../logos/danger.png";
import Standard2 from "../../logos/2.png";
import Warning3 from "../../logos/3.png";
import Danger4 from "../../logos/4.png";
import pdf from "../../logos/pdf.png";
import genericImage from "../../logos/generic.jpg";

import { connect } from "react-redux";
import { IPrescriptions } from "../../interfaces/IPrescriptions";
import { IMedicamentSelection } from "../../interfaces/IMedicamentSelection";
import Header from "../Common/Header";
import Footer from "../Common/Footer";

interface IProps {
  prescription: IPrescriptions;
  medicamentoSelecionadas: IMedicamentSelection;
  history: any[];
}

interface IState {
  medicamento: string;
  LeafletDocument: string;
  TechnicalDocument: string;
  Description: string;
  NationalCode: string;
  NRO: string;
  ATCCode: string;
  Dosification: string;
  Image: string;
  needsPrescription: boolean;
  isGeneric: boolean;
  AdministationWays: any;
  ActivePrinciples: any;
  Excipients: any;
  Comment: string;
  Valoration: string;
  face: string;
  generic: string;
  needs: string;
}

class DataSheet extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      medicamento: "",
      LeafletDocument: "",
      TechnicalDocument: "",
      Description: "",
      NationalCode: "",
      NRO: "",
      ATCCode: "",
      Dosification: "",
      Image: "",
      needsPrescription: false,
      isGeneric: false,
      AdministationWays: [],
      ActivePrinciples: [],
      Excipients: [],
      Comment: "",
      Valoration: "",
      face: "",
      generic: "",
      needs: "",
    };
    this.onReturn = this.onReturn.bind(this);
    this.colorBacgrounTable = this.colorBacgrounTable.bind(this);
    this.isGeneric = this.isGeneric.bind(this);
    this.needsPrescription = this.needsPrescription.bind(this);
  }

    componentDidMount() {
    window.scrollTo(0, 0)
    const documents = sessionStorage.getItem("documents");
    const {
      prescription: { resultsAll },
    } = this.props;

  
    if (documents === null || documents === undefined || resultsAll != undefined) {
      const {
        prescription: { resultsAll },
      } = this.props;

      this.setState({
        NationalCode: resultsAll.NationalCode,
        NRO: resultsAll.NRO,
        ATCCode: resultsAll.ATCCode,
        Dosification: resultsAll.Dosification,
        Image: resultsAll.Image,
        needsPrescription: resultsAll.needsPrescription,
        isGeneric: resultsAll.isGeneric,
        AdministationWays: resultsAll.AdministationWays,
        LeafletDocument: resultsAll.LeafletDocument,
        ActivePrinciples: resultsAll.ActivePrinciples,
        Excipients: resultsAll.Excipients,
        Description: resultsAll.Description,
        Comment: resultsAll.Comment,
        Valoration: resultsAll.Valoration,
        TechnicalDocument: resultsAll.TechnicalDocument,
      });

      const myArray = { resultsAll: resultsAll };
      sessionStorage.setItem("documents", JSON.stringify(myArray));
    } else if (documents != null || resultsAll === undefined) {
      const myArray = JSON.parse(documents);

      this.setState({
        NationalCode: myArray.resultsAll.NationalCode,
        NRO: myArray.resultsAll.NRO,
        ATCCode: myArray.resultsAll.ATCCode,
        Dosification: myArray.resultsAll.Dosification,
        Image: myArray.resultsAll.Image,
        needsPrescription: myArray.resultsAll.needsPrescription,
        isGeneric: myArray.resultsAll.isGeneric,
        LeafletDocument: myArray.resultsAll.LeafletDocument,
        AdministationWays: myArray.resultsAll.AdministationWays,
        ActivePrinciples: myArray.resultsAll.ActivePrinciples,
        Excipients: myArray.resultsAll.Excipients,
        Comment: myArray.resultsAll.Comment,
        Description: myArray.resultsAll.Description,
        Valoration: myArray.resultsAll.Valoration,
        TechnicalDocument: myArray.resultsAll.TechnicalDocument,
      });
    }
    setTimeout(() => {
      this.colorBacgrounTable();
      this.isGeneric();
      this.needsPrescription();
    }, 700);
  }

  colorBacgrounTable() {
    const { Valoration } = this.state;

    if (JSON.stringify(Valoration) === "1") {
      this.setState({ face: Good1 });
    } else if (JSON.stringify(Valoration) === "0.75") {
      this.setState({ face: Standard2 });
    } else if (JSON.stringify(Valoration) === "0.5") {
      this.setState({ face: "" });
    } else if (JSON.stringify(Valoration) === "0.25") {
      this.setState({ face: Warning3 });
    } else {
      this.setState({ face: Danger4 });
    }
  }

  isGeneric() {
    const { isGeneric } = this.state;

    isGeneric === true ? this.setState({ generic: ok }) : this.setState({ generic: danger });
  }

  needsPrescription() {
    const { needsPrescription } = this.state;

    needsPrescription === true ? this.setState({ needs: ok }) : this.setState({ needs: danger });
  }

  onReturn() {
    this.props.history.push("/search/intolerance");
  }


  render() {
    const {
      NationalCode,
      NRO,
      ATCCode,
      Dosification,
      Image,
      LeafletDocument,
      AdministationWays,
      ActivePrinciples,
      Excipients,
      Comment,
      Description,
      TechnicalDocument,
      face,
      generic,
    } = this.state;

    return (
        <div id="element" className="prescription-view">
        <Header />
        <div className="prescription-view-container">
          <div className="cold-data-sheet">
            <div className="container-result-data-sheet">
              <div className="container-fecha-resposive">
                <div className="flecha-reponsive">
                  <img className="image-flecha-responsive" src={flecha} onClick={this.onReturn} />
                </div>
              </div>
              <div className="title">
                <h4 className="text-tittle">{Description}</h4>
              </div>
            </div>
          </div>

          {Comment.length === 0 ? null : (
            <div className="note">
              <strong>Nota: </strong>
              <span>{Comment}</span>
            </div>
          )}

          <div className="image-generic-medicament">
            {Image === undefined ? (
              <img className="img-data-sheet" src={genericImage} />
            ) : (
              <figure>
                <img className="img-data-sheet" src={Image} />
                <figcaption>Fuente: AEMPS</figcaption>
              </figure>
            )}
          </div>

          <div className="excipient-way-active">
            <div className="box-item">
              <h5 className="text-tittle-list">Vias de administracion</h5>
              <ul>
                {AdministationWays.map((items: any) => (
                  <li className="text-list">{items.Name}</li>
                ))}
              </ul>
            </div>

            <div className="box-item">
              <h5 className="text-tittle-list">Principios activos</h5>
              <ul>
                {ActivePrinciples.map((items: any) => (
                  <li className="text-list">{items.Name}</li>
                ))}
              </ul>
            </div>

            <div className="box-item">
              <h5 className="text-tittle-list">Excipientes</h5>
              <ul>
                {Excipients.map((items: any) => (
                  <li className="text-list">{items.Edo}</li>
                ))}
              </ul>
            </div>
          </div>

          <div className="item-info">
            <p className="text-tittle-list">
              <strong>Dosificacion: </strong>
              <span className="text-tittle-list-data-sheet">{Dosification}</span>
            </p>
          </div>
          <div className="item-info ">
            <p className="text-tittle-list">
              <strong>Codigo Nacional: </strong>
              <span className="text-tittle-list-data-sheet">{NationalCode}</span>
            </p>
          </div>
          <div className="item-info">
            <p className="text-tittle-list">
              <strong>Clasificacion anatomica: </strong>
              <span className="text-tittle-list-data-sheet">{ATCCode}</span>
            </p>
          </div>

          <div className="item-info">
            <p className="text-tittle-list">
              <strong>Codigo NRO: </strong>
              <span className="text-tittle-list-data-sheet">{NRO}</span>
            </p>
          </div>

          <div className="item-info">
            <p className="text-tittle-list">
                  <strong>Valoracion: </strong>
                  <span className="span-valoration-list">
                <img width="25px" src={face} />
              </span>
            </p>
          </div>
          <div className="item-info  ">
            <p className="text-tittle-list">
              <strong>Necesita receta: </strong>
              <span>
                <img width="40px" src={generic} />
              </span>
            </p>
          </div>
          <div className="item-info ">
            <p className="text-tittle-list">
              <strong>Generico: </strong>
              <span>
                <img width="40px" src={generic} />
              </span>
            </p>
          </div>

          {(LeafletDocument || TechnicalDocument) && (
            <div className="downloads">
              <div className="head-separator">Ficheros disponibles</div>

              {TechnicalDocument && (
                <div className="pdf-container">
                  <a target="blank" download className="boton" href={TechnicalDocument}>
                    <img width="90px" src={pdf} />
                    <p className="text-pdf-container">Ficha tecnica (Fuente: AEMPS)</p>
                  </a>
                </div>
              )}

              {LeafletDocument && (
                <div className="pdf-container">
                  <a target="blank" download className="boton" href={LeafletDocument}>
                    <img width="90px" src={pdf} />
                    <p className="text-pdf-container">Prospecto (Fuente: AEMPS)  </p>
                  </a>
                </div>
              )}
            </div>
          )}
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = ({ prescription, medicamentoSelecionadas }: IProps) => ({
  prescription,
  medicamentoSelecionadas,
});

export default connect(mapStateToProps)(DataSheet);
