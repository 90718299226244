import React from "react";
import med from "../../images/med.png";
import arrow from "../../images/arrow.png";
import "../../styles/styles.css";
import { connect } from "react-redux";
import { setMedicamentAction } from "../../redux/actions";
import { IMedicamentSelection } from "../../interfaces/IMedicamentSelection";
import Header from "../Common/Header";
import Footer from "../Common/Footer";

interface IProps {
  history: string[];
  medicament: string;
}

interface IGlobalActionProps {
  setMedicament(medicament: IMedicamentSelection): void;
}

type TProps = IProps & IGlobalActionProps;

class Main extends React.PureComponent<TProps> {
  constructor(props: TProps) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(event: any) {
    sessionStorage.removeItem("prescription");
    const prescription = event.target.value;

    if (event.charCode === 13 && prescription) {
      this.props.setMedicament({ prescription });
      this.props.history.push("/search");
    }
  }

    render() {
    return (
        <div className="home">
        <Header />
        <div className="cols">
          <div
            className="left-col"
            style={{
              backgroundImage: `url(${med})`,
            }}
          ></div>
          <div className="right-col">
            <div className="search-form">
              <div className="text">Comprueba si un medicamento es compatible con tu intolerancia alimentaria</div>
              <div className="arrow" style={{ backgroundImage: `url(${arrow})` }}></div>
              <input
                type="email"
                className="form-control inputSearch"
                placeholder="Busca por nombre comercial, principio activo o código nacional"
                onKeyPress={this.onSubmit}
              />
            </div>
          </div>
         </div>
        <Footer />
      </div>
    );
  }
}

const mapDispatchToProps = {
  setMedicament: setMedicamentAction,
};

export default connect(null, mapDispatchToProps)(Main);
