import React from "react";

import { setViaAction } from "../../redux/actions";
import { connect } from "react-redux";
import { IViaAdministration } from "../../interfaces/IViaAdministration";

import "./style.css";
import DataApiVias from "../../APIVias/DataApiVias";

interface IProps {
  viaAdministration: IViaAdministration;
}

interface IState {
  itemsVias: any;
  administrationWayId: string;
}

interface IGlobalActionPreops {
  setViaAdministration(viaAdministration: IViaAdministration): void;
}

type TProps = IProps & IGlobalActionPreops;

class AdministrationWeyInput extends React.PureComponent<TProps, IState> {
  constructor(props: TProps) {
    super(props);

    this.state = {
      itemsVias: [],
      administrationWayId: "",
    };

    this.onSubmitViaAdministration = this.onSubmitViaAdministration.bind(this);
    this.callsApiVias = this.callsApiVias.bind(this);
  }

  async componentDidMount() {
    const results: any[] = await DataApiVias.Search(); //BORRAR 
    console.log(results);
    const { viaAdministration } = this.props;


    this.setState({ administrationWayId: viaAdministration.viaAdministration });
    const data = this.callsApiVias();
    
    this.setState({ itemsVias: data });
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.viaAdministration !== prevProps.viaAdministration) {
      const { viaAdministration } = this.props;

      this.setState({ administrationWayId: viaAdministration.viaAdministration });
    }
  }

  onSubmitViaAdministration(e: any) {
    const viaAdministration = e.target.value;
    const via = JSON.parse(viaAdministration);

    this.setState({ administrationWayId: via.code });
    this.props.setViaAdministration({ viaAdministration: via.code });
  }

  callsApiVias() {
    const resultsVias: any = [
      {code: 10, name: "Cualquier vía"},
      {code: 54, name: "VÍA ORAL"},
      {code: 99, name: "VÍA PARENTERAL"},
      {code: 15, name: "OTRAS"}
    ];
    
    return resultsVias;
  }

  render() {
    const { itemsVias, administrationWayId } = this.state;

    return (
      <>
        <select className="form-control via-selector" onChange={this.onSubmitViaAdministration}>
          {itemsVias.map((items: any, value: any) =>
            administrationWayId === items.code ? (
              <option selected key={value} value={JSON.stringify(items)}>
                {items.name}
              </option>
            ) : (
              <option key={value} value={JSON.stringify(items)}>
                {items.name}
              </option>
            )
          )}
        </select>
      </>
    );
  }
}

const mapDispatchToProps = {
  setViaAdministration: setViaAction,
};

const mapStateToProps = ({ viaAdministration }: IProps) => ({
  viaAdministration,
});

export default connect(mapStateToProps, mapDispatchToProps)(AdministrationWeyInput);
