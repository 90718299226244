export default class Color {
    static Good() {
        const colorRangeGreen = [
            {
                color1: "#4ec6a3",
                color2: "#5adba1",
                color3: "#70ebb4",
                color4: "#8fffc3"
            }
        ];

        return colorRangeGreen;
    }

    static Standard() {
        const colorRangeBlue = [
            {
                color1: "#71c4cd",
                color2: "#83dadb",
                color3: "#8de6df",
                color4: "#aafaed"
            }
        ];

        return colorRangeBlue;
    }

    static Warning() {
        const colorRangeOrange = [
            {
                color1: "#ffa033",
                color2: "#fdb237",
                color3: "#ffcf33",
                color4: "#ffee03"
            }
        ];

        return colorRangeOrange;
    }

    static Danger() {
        const colorRangeRed = [
            {
                color1: "#f24e4e",
                color2: "#fc7768",
                color3: "#ff8987",
                color4: "#ffa3a3"
            }
        ];

        return colorRangeRed;
    }
    
    static Unclassified() {
        const unclassified = [
            {
                color1: "#6E6E6E",
                color2: "#848484",
                color3: "#A4A4A4",
                color4: "#BDBDBD"
            }
        ];

        return unclassified;
    }
}
