import React, { Component } from "react";

import { Switch, Route, BrowserRouter } from "react-router-dom";

import Main from "./componets/Main/Main";
import SearchIntolerance from "./componets/SearchIntolerance/SearchIntolerance";
import Searches from "./componets/Searches/Searches";
import DataSheet from "./componets/DataSheet/DataSheet";

class App extends React.PureComponent {
    render() {
        return (
            <>
                <BrowserRouter>
                    <Switch>
                        <Route path="/" exact component={Main} />
                        <Route
                            path="/search"
                            exact
                            component={(props: any) => (
                                <SearchIntolerance {...props} />
                            )}
                        />
                        <Route
                            path="/search/intolerance"
                            exact
                            component={Searches}
                        />
                        <Route
                            path="/searches/dataSheet"
                            exact
                            component={(props: any) => <DataSheet {...props} />}
                        />
                    </Switch>
                </BrowserRouter>
            </>
        );
    }
}

export default App;
