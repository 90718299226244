import React from "react";

import "./style.css";
import loadingLogo from "../../loadingLogo/LFYM.gif";

class Loading extends React.PureComponent{
    render(){
        return(
            <div className="loading">
                <img src={loadingLogo} alt="loading..." />
            </div>
        )
    }
}

export default Loading;