import Prescription from "../Entities/Prescription";
import AdministrationWayMapper from "./AdministrationWayMapper";
import ActivePrinciplesMapper from "./ActivePrinciplesMapper";
import ExcipientsMapper from "./ExcipientsMapper";
import IntoleranceMapper from "./IntolerancesMapper";
import ClassificationMapper from "./ClassificationMapper";

export default class PrescriptionMapper {
    /**
     * Genera un objeto PrescriptionMapper partido de un json
     * @param item
     */
    static map(item: any): Prescription {
        const prescription = new Prescription();
        prescription.NationalCode = item.NationalCode;
        prescription.NRO = item.NRO;
        prescription.ATCCode = item.ATCCode;
        prescription.Description = item.Description;
        prescription.Dosification = item.Dosification;
        prescription.LeafletDocument = item.LeafletDocument;
        prescription.TechnicalDocument = item.TechnicalDocument;
        prescription.isGeneric = item.isGeneric;
        prescription.needsPrescription = item.needsPrescription;
        prescription.Image = item.Image;
        
        prescription.AdministationWays = AdministrationWayMapper.mapArray(
            item.AdministationWay
        );
        prescription.ActivePrinciples = ActivePrinciplesMapper.mapArray(
            item.ActivePrinciples
        );
        prescription.Excipients = ExcipientsMapper.mapArray(
            item.Excipients
        );
        prescription.Valoration = item.Valoration;
        prescription.Comment = item.Comment;
       
        return prescription;
    }

    /**
     * Genera un arrays de objetos PrescriptionMapper partido de un json
     * @param items
     */
    static mapArray(items: any[]): Prescription[] {
        return items.map(item => {
            return PrescriptionMapper.map(item._source.Data);
        });
    }
}
