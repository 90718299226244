export default class CallsApi {
    static API_URL: string = "https://checkmedicine.fiibhuilhuse.es/";

    static async GET(endpoint: any) {   
        const response = await fetch(CallsApi.API_URL + endpoint );  
        const json = await response.json();
        
        return json;
    }
}
