import AdministrationWay from "../Entities/AdministrationWay";

export default class AdministrationWayMapper {
    /**
     * Genera un objeto AdministrationWay partido de un json
     * @param item
     */
    static map(item: any): AdministrationWay {
        const administrationWay = new AdministrationWay();
        administrationWay.Code = item.Code;
        administrationWay.Name = item.Name;

        return administrationWay;
    }

    /**
     * Genera un arrays de objetos AdministrationWay partido de un json
     * @param item
     */
    static mapArray(items: any[]): AdministrationWay[] {   
        return items.map(item => {
            return AdministrationWayMapper.map(item);
        });
    }
}
