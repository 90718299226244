import React from "react";
import aihf from "../../logos/logo_AAIHF.png";
import aemps from "../../logos/aemps-m.jpeg";
import meytel from "../../logos/logocabecera.png";
import cinfa from "../../logos/Cinfa.png";
import fundacion from "../../logos/logo_fundación.png";
import farmacia from "../../logos/LOGO_farmacia_sin_fondo.png";
 
interface FooterState {
  version: string;
}
 
export default class Footer extends React.PureComponent<{}, FooterState> {
  constructor(props:{}) {
    super(props);
    this.state = {
      version: "",
    };
  }
 
  componentDidMount() {
    fetch('https://checkmedicine.fiibhuilhuse.es/version.txt')
      .then(response => response.text())
     .then(data => this.setState({ version: data.trim() }))
      .catch(error => console.error("Error fetching version.txt:", error));
  }

  
  render() {
    return (
      <div className="container-footer">        
        <div>
          <p className="text-discalimer">
            Los contenidos de esta App y Sitio Web tienen fines exclusivamente informativos, procedentes de fuentes propias y/o de terceros. Los usuarios deben tener presente que sus contenidos no constituyen un asesoramiento médico ni jurídico.
            <br/>La Asociación de Afectados por Intolerancia Heraditaria (AAIHF), la Fundación para la Innovación e Investigación Biomédica del Hospital Universitario Infanta Leonor y Hospital del Sureste (FIIBHUILHUSE) y el Servicio de Farmacia del Hospital Universitario Infanta Leonor no quedan obligadas a comprobar la veracidad, exactitud, adecuación, idoneidad, exhaustividad y actualidad de la información ofrecida a través de esta App y el Sitio Web, ni serán responsables de los daños y perjuicios que se pudieran producir en el Usuario y/o en terceros como consecuencia de haber actuado basándose en la información obtenida del mismo.
            <br/><i>Fuente de la información: Agencia Española de Medicamentos y Productos Sanitarios <a href='https://www.aemps.gob.es' target='_blank'>www.aemps.gob.es</a></i>
            &nbsp;| &nbsp;<i>Fecha de Actualización:  {this.state.version}</i>
          </p>
        </div>
        <div className="footer">
          <a href="https://checkmedicine.fiibhuilhuse.es/aviso-legal/">Aviso Legal</a> |
          <a href="https://checkmedicine.fiibhuilhuse.es/politica-de-privacidad/">Política de Privacidad</a> |
          <a href="https://checkmedicine.fiibhuilhuse.es/politica-de-cookies-ue/">Política de Cookies (UE)</a> |
          <a href="https://checkmedicine.fiibhuilhuse.es/ayuda/">Ayuda</a>
        </div>
        <div className="footer">
          {/* <img className="aemps" src={aemps} /> */}
          <a href="https://www.comunidad.madrid/hospital/infantaleonor/" target="_blank"><img className="fundacion" src={fundacion} /></a>
          {/* <a href="https://www.meytel.net" target="_blank"><img className="meytel" src={meytel} /></a> */}
          <a href="https://www.comunidad.madrid/hospital/infantaleonor/profesionales/servicios-centrales/farmacia" target="_blank"><img className="farmacia" src={farmacia} /></a>
          {/* <img className="cinfa" src={cinfa} /> */}
          {/* <p className="text-cinfa">Proyecto financiado por cinfasalud: Contigo, 50 y más</p> */}
          <a href="https://www.aaihf.com" target="_blank"><img className="aihf" src={aihf} /></a>
        </div>
        
      </div>
      
    );
  }
}
 