import ActivePrinciples from "./ActivePrinciples";
import AdministrationWay from "./AdministrationWay";
import Excipients from "./Excipients";
import Intolerances from "./Intolerances";
import Classification from "./Classification";

export default class Prescription {

    NationalCode: string = "";
    NRO: string = "";
    ATCCode: string = "";
    Description: string = "";
    Dosification: string = "";
    LeafletDocument: string ="";
    TechnicalDocument: string = "";
    isGeneric: string = "";
    needsPrescription: string = "";
    Image: string = "";
    AdministationWays: AdministrationWay[] = [];
    ActivePrinciples: ActivePrinciples[] = [];
    Excipients: Excipients[] = [];
    Classification: Classification[] = [];
    Intolerances: Intolerances[] = [];
    Valoration: string = "";
    Comment: string = "";
    
}
