import CallsApi from "./CallsApi";
import Prescription from "./Entities/Prescription";
import PrescriptionMapper from "./Mappers/PrescripciotnMapper";

export default class DataApi {
    static async Search(
        query: string,
        via: string,
        intolerance: string
    ): Promise<any> {
        console.log(intolerance)
        const itoleranceOneSelec = DataApi.comprovationIntolerance(intolerance);
        const viaSelect = DataApi.comprovationVia(via);

        if (itoleranceOneSelec.length === 0) {
            const result: any = await CallsApi.GET("wp-json/aihf/prescription/search?q=" + query + "&i=" + itoleranceOneSelec + "&via=" + viaSelect);
           
            const results: any[] = [result.data];
            const prescriptions: Prescription[] = PrescriptionMapper.mapArray(
                results[0].hits.hits
            );

            return { prescriptions };

        } else {
            const result: any = await CallsApi.GET("wp-json/aihf/prescription/search?q=" + query + "&i=" + itoleranceOneSelec + "&via=" + viaSelect);
           
            const results: any[] = [result.data];
            const prescriptions: Prescription[] = PrescriptionMapper.mapArray(
                results[0].hits.hits
            );

            return { prescriptions };

        }

    }

    static comprovationIntolerance(intolerance: any) {
        if (intolerance === undefined) {
            return intolerance = "";

        } else {
            let cont: any = "";

            intolerance.map((items: any) => {
                let item = cont;

                cont = items.id + "," + item;
            });

            let numIntolerances =  cont.substring(0, cont.length - 1)

            return numIntolerances;
        }
    }

    static comprovationVia(via: any) {
        if (via === undefined) {
            return via = "";
        }
        return via;
    }
}

