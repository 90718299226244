import Vias from "../Entities/Vias";

export default class ViasMapper {
    /**
     * Genera un objeto PrescriptionMapper partido de un json
     * @param item
     */
    static map(item: any): Vias {
        const via = new Vias();
        via.code = item.Code;
        via.name = item.Name;

        return via;
    }

    /**
     * Genera un arrays de objetos PrescriptionMapper partido de un json
     * @param items
     */
    static mapArray(items: any[]): Vias[] {
        return items.map(item => {
            return ViasMapper.map(item._source.Data);
        });
    }
}