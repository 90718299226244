import React from "react";

import "../style.css";

interface IProps {
    itemsIntolerances: any;
    data: any;
    onPress: any;
}

interface IState {
    checkcout: boolean;
}

class Checkbox extends React.PureComponent<IProps, IState>{
    constructor(props: IProps) {
        super(props)


        this.state = {
            checkcout: false
        }
        this.weCheck = this.weCheck.bind(this);
        this.changeState = this.changeState.bind(this);
    }

    componentDidMount() {
        const { itemsIntolerances, data } = this.props;
        
        if (itemsIntolerances != undefined) {
            const weCheck = this.weCheck(itemsIntolerances, data);
            if (weCheck === true) {
                const index = itemsIntolerances.findIndex((index: any) => { return index.id == data.id });
                const item = itemsIntolerances[index];
                
                this.setState({ checkcout: true });
            }

        } else {
            this.setState({ checkcout: false });
           
        }
    }

    weCheck(intoleranciasSelecionadas: any, data: any) {

        return intoleranciasSelecionadas.findIndex((p: any) => {
            return p.id == data.id;
        }) >= 0;

    }

    changeState(checkcout: boolean) {
        checkcout === false ? this.setState({ checkcout: true }) : this.setState({ checkcout: false });
    }

    render() {
        const { data } = this.props;
        const { checkcout } = this.state;
       

        return (
            <>
                {checkcout === true  ? 
                    <input checked={checkcout} type="checkbox" id="post_title" value={data.id} onChange={(e) => this.props.onPress(e)} onClick={() => this.changeState(checkcout)}/> :
                    <input checked={checkcout} type="checkbox" id="post_title" value={data.id} onChange={(e) => this.props.onPress(e)} onClick={() => this.changeState(checkcout)} />
                }

                <label id="title-checkbox" className="title-checkbox" htmlFor={data.post_title}>{data.post_title}</label><br />
            </>
        );

    }
}


export default Checkbox;

