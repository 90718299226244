import Vias from "./Entities/Vias";
import CallsApi from "./CallsAiVias";
import ViasMapper from "./Mappers/ViasMapper";

export default class DataApiVias {
    static async Search(): Promise<any> {
        const result: any = await CallsApi.GET();
        const results: any[] = [result.data]; 

        const vias: Vias[] = ViasMapper.mapArray(
            results[0].hits.hits
        );
            
        return { vias };
    }
}
