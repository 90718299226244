import Intolerances from "../API/Entities/Intolerances";
import CallsApi from "./CallsAiIntolerance";
import IntolerancesMapper from "./Mappers/IntolencesMapper";

export default class DataApiIntolerance {
    static async Search(): Promise<any> {
        const result: any = await CallsApi.GET();
        const results: any[] = [result.data]; 

        const intolerances: any = IntolerancesMapper.mapArray(
           results[0].hits.hits
        );
            
        return { intolerances };
    }
}
