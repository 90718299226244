import { IPrescriptions } from './../interfaces/IPrescriptions';
import { IIntoleranceSelection } from '../interfaces/IIntoleranceSelection';
import {IMedicamentSelection} from '../interfaces/IMedicamentSelection';
import { IViaAdministration } from '../interfaces/IViaAdministration';
import { IIntoleranceTwoSelection } from '../interfaces/IIntoleranceTwoSelection';
import { TAction } from './types';


export const setMedicamentAction = (medicamentoSelecionadas: IMedicamentSelection): TAction => ({
    type: 'SET_MEDICAMENT',
    payload: medicamentoSelecionadas
});

export const setIntoleranceAction = (intoleranciasSelecionadas: IIntoleranceSelection): TAction => ({
    type: 'SET_INTOLERANCE',
    payload: intoleranciasSelecionadas
});

export const setIntoleranceTwoAction = (intoleranciasSelecionadasTwo: IIntoleranceTwoSelection): TAction => ({
    type: 'SET_TWO_INTOLERANCE',
    payload: intoleranciasSelecionadasTwo
});

export const setViaAction = (viaAdministration: IViaAdministration): TAction => ({
    type: 'SET_VIA',
    payload: viaAdministration
});

export const setPrescriptionAction = (prescription: IPrescriptions): TAction => ({
    type: 'SET_PRESCRIPTION',
    payload: prescription
})