import Excipients from "../Entities/Excipients";

export default class ExcipientsMapper {
    /**
     * Genera un objeto ExcipientsMapper partido de un json
     * @param items
     */
    static map(item: any): Excipients {
        const excipients = new Excipients();
        excipients.Code = item.Code;
        excipients.Edo = item.Edo;
        excipients.indexType = item.indexType;
        excipients.indexId = item.indexId;

        return excipients;
    }
    /**
     * Genera un arrays de objetos ExcipientsMapper partido de un json
     * @param items
     */
    static mapArray(items: any[]): Excipients[] {
        return items.map(item => {
            return ExcipientsMapper.map(item);
        });
    }
}
